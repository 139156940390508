<template>
	<v-card flat class="wr_grey_1">
		<v-card-text>
			<component
				v-if="current_step"
				:is="current_step.component_name"
			></component>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import AskQuestion from "@/components/shared/tools/query/mindmap/steps/AskQuestion.vue";
import PreviewMap from "@/components/shared/tools/query/mindmap/steps/PreviewMap.vue";
import ChooseFav from "@/components/shared/tools/query/mindmap/steps/ChooseFav.vue";
import TakeActions from "@/components/shared/tools/query/mindmap/steps/TakeActions.vue";
import AddAdditionalInfo from "@/components/shared/tools/query/mindmap/steps/AddAdditionalInfo.vue";
import GenerateReport from "@/components/shared/tools/query/mindmap/steps/GenerateReport.vue";

export default {
	name: "Query",

	components: {
		AskQuestion,
		PreviewMap,
		ChooseFav,
		TakeActions,
		AddAdditionalInfo,
		GenerateReport
	},

	computed: {
		...mapFields("query", {
			current_step: "mindmap.current_step",
		}),
	},
};
</script>

<style></style>
