<template>
	<div>
		<v-form ref="form">
			<v-row>
				<v-col>
					<v-text-field
						v-model="step1_question"
						:label="$t('app.topic_curious_about')"
						:rules="[rules.required, rules.maxWords(5)]"
						outlined
					>
						<span slot="append">
							<SpeechToText
								:text="step1_question"
								@speechend="onSpeechEnd($event.text)"
							></SpeechToText>
						</span>
						<span slot="append" class="ms-3">
							<TextToSpeech :text="step1_question"></TextToSpeech>
						</span>
						<span slot="append" class="ms-3">
							<Translator :text="step1_question"></Translator>
						</span>
					</v-text-field>
				</v-col>
				<!-- <v-col sm="4">
					<BackgroundInformation></BackgroundInformation>
				</v-col> -->
				<v-col sm="2">
					<AppButton
						label="app.submit"
						:rounded="false"
						:block="true"
						:x_large="true"
						icon="send"
						@click="submit()"
						color="success"
					></AppButton>
				</v-col>
			</v-row>
		</v-form>

		<v-card v-if="step1_map" flat height="900px" class="wr_grey_1">
			<StandardMindmap
				:map_data="step1_map"
				:readonly_mode="true"
			></StandardMindmap>
		</v-card>
	</div>
</template>

<script>
import { generateRandomString } from "@/utils/helpers";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import { mapFields } from "vuex-map-fields";
import { queryMindmapSteps } from "@/utils/steps";
import BackgroundInformation from "@/components/shared/tools/query/_common/BackgroundInformation.vue";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "MindmapAskQuestion",

	mixins: [askQuestionMixin, toolsQueryMixin],

	components: {
		StandardMindmap,
		BackgroundInformation,
	},

	computed: {
		...mapFields("query", {
			step1_answer: "mindmap.step1_answer",
			step1_question: "mindmap.step1_question",
			current_step: "mindmap.current_step",
			step1_map: "mindmap.step1_map",
			step2_map: "mindmap.step2_map",
			step2_answer: "mindmap.step2_answer",
			step3_map: "mindmap.step3_map",
			step3_branches: "mindmap.step3_branches",
			step3_answer: "mindmap.step3_answer",
			attempts: "mindmap.attempts",
			grade: "mindmap.grade",
		}),
	},

	watch: {
		step1_question(newVal, oldVal) {
			this.step1_answer = this.step1_map = this.step2_map = this.step2_answer = this.step3_map = this.step3_branches = this.step3_answer = null;
			this.attempts = [];
		},
	},

	mounted() {
		this.prepareMapData();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtm-move-to-map-preview", (payload) => {
				try {
					if (!this.step1_question || !this.step1_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}
					this.current_step = queryMindmapSteps[1];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				this.$loader.start();

				if (!this.$refs.form.validate()) return;

				this.step1_answer = await this.prepareQuery(
					`Generate a JSON representation of a mind map about ${this.step1_question} with 4 branches. In the first branch add 3 sub-categories. In the second branch add 2 sub-categories. In the fourth branch add 1 sub-category. In third branch do not add sub-category. JSON should be in this format- {"name": "node_name", "children": [ { name: "node_name" } ]}`,
				);

				this.prepareMapData();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		prepareMapData() {
			try {
				if (!this.step1_answer || !this.step1_answer.result) return;

				let result = structuredClone(JSON.parse(this.step1_answer.result));
				if (!result) return;

				let obj = {};
				obj.topic = result.name;
				obj.expanded = true;
				obj.fav = false;
				obj.id = generateRandomString();
				obj.notes = [];
				obj.parent = undefined;
				obj.root = true;
				obj.children = structuredClone(result.children);

				preparechild(obj.children);

				function preparechild(arr) {
					if (arr) {
						arr.map((child) => {
							child.topic = child.name;
							child.fav = false;
							child.id = generateRandomString();
							child.notes = [];
							delete child.name;

							if (child.children) {
								preparechild(child.children);
							}
						});
					}
				}

				let map = {};
				map.category = "tools";
				map.id = generateRandomString();
				map.name = "gpt_generated";
				map.selected = false;
				map.data = {
					linkData: {},
					nodeData: obj,
				};

				this.step1_map = structuredClone(map);
			} catch (error) {
				console.error(error);
			}
		},

		onSpeechEnd(text) {
			try {
				// Save emitted value by microphone to answer
				this.step1_question = text;

				/**
				 * When click on mic icon, the focus will be detached from the input, so,
				 * after off the mic, enable the focus to input element again because
				 * answer will be saved only on blur event and to trigger blur event
				 * we need to focus first.
				 */
				this.$nextTick(() => {
					// There are two index in $el array, 0th is label and 1st is input.
					this.$refs.form.$el[1].focus();
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtm-move-to-map-preview");
	},
};
</script>

<style></style>
