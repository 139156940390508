<template>
	<div>
		<v-row no-gutters>
			<v-col>
				<v-card v-if="step4_answer" flat class="wr_grey_1">
					<v-card-text
						class="pt-0 black--text"
						v-if="step4_answer.result"
						:class="answer_styles"
						:key="'r'"
						v-html="step4_answer.result.replace('\n\n', '')"
					></v-card-text>
				</v-card>
			</v-col>
			<v-col sm="2" align="center">
				<AppButton
					label="app.submit"
					:rounded="false"
					:x_large="true"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</v-col>
		</v-row>

		<v-card v-if="step3_map" flat height="700px" class="wr_grey_1 mt-4">
			<StandardMindmap
				:map_data="step3_map"
				:readonly_mode="true"
				:show_fav_icon="true"
				@on-map-saved="onMapSave"
			></StandardMindmap>
		</v-card>
	</div>
</template>

<script>
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import { mapFields } from "vuex-map-fields";
import { flatArrayOfObjects, filterArray } from "@/utils/helpers";
import { queryMindmapSteps } from "@/utils/steps";
import {
	toolsQueryMixin,
	mindmapJSONMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "EvaluateMap",

	mixins: [toolsQueryMixin, mindmapJSONMixin],

	components: {
		StandardMindmap,
	},

	computed: {
		...mapFields("query", {
			step2_map: "mindmap.step2_map",
			step3_map: "mindmap.step3_map",
			step4_answer: "mindmap.step4_answer",
			current_step: "mindmap.current_step",
		}),
	},

	methods: {
		onMapSave(payload) {
			this.$store.commit("query/UPDATE_MAP", {
				step: 3,
				data: payload.data,
			});
		},

		readyToListenEvents() {
			this.$eventBus.$on("qtm-move-to-actions", (payload) => {
				try {
					if (!this.step3_map) {
						throw new Error("There is no map.", {
							cause: "werCustom",
						});
					}

					let fav_nodes = this.parseFavNodes();
					if (!fav_nodes || fav_nodes.length < 3) {
						throw new Error(
							"Please mark at least 3 topics from the mind map.",
							{
								cause: "werCustom",
							},
						);
					}

					if (!this.step4_answer) {
						throw new Error(
							"Please submit the mind map before moving to the next step.",
							{
								cause: "werCustom",
							},
						);
					}

					this.current_step = queryMindmapSteps[4];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		parseFavNodes() {
			let arr = flatArrayOfObjects(
				this.step3_map.data.nodeData.children,
				"children",
				[],
			);
			// If root node is fav, count it too
			if (this.step3_map.data.nodeData.fav) {
				arr.push({
					topic: this.step3_map.data.nodeData.topic,
					fav: this.step3_map.data.nodeData.fav,
				});
			}
			return filterArray(arr, "fav", true, true);
		},

		async submit() {
			try {
				this.$loader.start();

				let fav_nodes = this.parseFavNodes();
				if (!fav_nodes || fav_nodes.length < 3) {
					throw new Error("At least 3 nodes should be marked as favourite.", {
						cause: "werCustom",
					});
				}

				fav_nodes = fav_nodes.map((item) => item.topic);

				let modified_json = this.reverseJSON(this.step3_map);

				let query = `In the following mindmap explain if and how these nodes, `;

				fav_nodes.forEach((node) => {
					query += `${node}, `;
				});

				query +=
					"are related. If a node is not related, explain why?. The map is- ";
				query += `${JSON.stringify(modified_json)}`;

				this.step4_answer = await this.prepareQuery(query);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtm-move-to-actions");
	},
};
</script>

<style></style>
