<template>
	<div>
		<v-row no-gutters>
			<v-col>
				<v-card v-if="step2_answer" flat class="wr_grey_1">
					<v-card-text
						class="pt-0 black--text"
						v-if="step2_answer.result"
						:class="answer_styles"
						:key="'r'"
						v-html="step2_answer.result.replace('\n\n', '')"
					></v-card-text>
				</v-card>
			</v-col>
			<v-col sm="2" align="center">
				<AppButton
					label="app.submit"
					:rounded="false"
					:x_large="true"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</v-col>
		</v-row>

		<v-card v-if="step2_map" flat height="700px" class="wr_grey_1 mt-4">
			<StandardMindmap
				:map_data="step2_map"
				@on-map-saved="onMapSave"
				@operation-performed="onOperationPerformed"
			></StandardMindmap>
		</v-card>
	</div>
</template>

<script>
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import { mapFields } from "vuex-map-fields";
import { queryMindmapSteps } from "@/utils/steps";
import {
	toolsQueryMixin,
	mindmapJSONMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "PreviewMap",

	mixins: [toolsQueryMixin, mindmapJSONMixin],

	data() {
		return {
			temp: [],
		};
	},

	components: {
		StandardMindmap,
	},

	computed: {
		...mapFields("query", {
			step1_question: "mindmap.step1_question",
			step1_map: "mindmap.step1_map",
			step2_map: "mindmap.step2_map",
			step2_answer: "mindmap.step2_answer",
			current_step: "mindmap.current_step",
			attempts: "mindmap.attempts",
		}),
	},

	mounted() {
		if (!this.step2_map) {
			this.step2_map = structuredClone(this.step1_map);
		}
	},

	methods: {
		onMapSave(payload) {
			this.$store.commit("query/UPDATE_MAP", {
				step: 2,
				data: payload.data,
			});
		},

		readyToListenEvents() {
			this.$eventBus.$on("qtm-move-to-map-evaluation", (payload) => {
				try {
					if (!this.step2_map) {
						throw new Error("There is no map.", {
							cause: "werCustom",
						});
					}

					this.isMapModified(this.step1_map, this.step2_map);

					if (!this.step2_answer) {
						throw new Error("Please submit the mind map to get a feedback..", {
							cause: "werCustom",
						});
					}

					this.current_step = queryMindmapSteps[2];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				this.$loader.start();

				this.isMapModified(this.step1_map, this.step2_map);

				let modified_json = this.reverseJSON(this.step2_map);

				this.step2_answer = await this.prepareQuery(
					`Check each node if it should appear in this mind map then generate an array of the nodes that should not appear - ${JSON.stringify(
						modified_json,
					)}`,
				);

				// Push this answer as an attempt
				this.attempts.push({
					step: 2,
					round: this.currentRound(2),
					data: this.temp,
					feedback: this.step2_answer.result,
				});

				this.temp = [];
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtm-move-to-map-evaluation");
	},
};
</script>

<style></style>
