<template>
	<div>
		<v-form ref="form">
			<!-- Student name -->
			<v-text-field
				v-model="form.user_name"
				:label="$t('app.name')"
				outlined
				:rules="[rules.required]"
			></v-text-field>

			<!-- Teacher name -->
			<v-text-field
				v-model="form.teacher_name"
				:rules="[rules.required]"
				:label="$t('app.teacher_name')"
				outlined
			></v-text-field>

			<!-- Student grade -->
			<!-- <v-text-field
				v-model="form.grade"
				readonly
				:label="$t('app.grade')"
				outlined
			></v-text-field> -->

			<!-- Student reflection -->
			<v-text-field
				v-model="form.reflection"
				:rules="[rules.required]"
				:label="$t('app.reflection')"
				outlined
			></v-text-field>
		</v-form>

		<v-card v-if="step3_map" flat height="700px" class="wr_grey_1 mt-4">
			<StandardMindmap :map_data="step3_map"></StandardMindmap>
		</v-card>

		<!-- Table -->
		<table class="mt-4">
			<thead>
				<tr class="subtitle-1">
					<th>{{ $t("app.step_round") }}</th>
					<th colspan="3">
						{{ $t("app.items_generated_by_user") }}
					</th>
				</tr>
				<tr class="subtitle-1">
					<th></th>
					<th>{{ $t("app.total_placed") }}</th>
					<th>{{ $t("app.placed_correctly") }}</th>
					<th>{{ $t("app.placed_incorrectly") }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in table_data" :key="index">
					<td>
						{{ $t("app.step") }} {{ item.step }} - {{ $t("app.round") }}
						{{ item.round }}
					</td>
					<td>
						{{ item.placed.length }}
					</td>
					<td>
						<span v-if="item.placed_correctly.length">
							{{ item.placed_correctly.length }}
							<br />
							({{ item.placed_correctly.join(", ") }})</span
						>
						<span v-else>{{ item.placed_correctly.length }}</span>
					</td>
					<td>
						<span v-if="item.placed_incorrectly.length">
							{{ item.placed_incorrectly.length }}
							<br />
							({{ item.placed_incorrectly.join(", ") }})
						</span>
						<span v-else>{{ item.placed_incorrectly.length }}</span>
					</td>
				</tr>
			</tbody>
		</table>

		<v-card flat class="mt-4">
			<v-card-text class="subtitle-1 black--text ps-0">
				{{ $t("app.help_me_used") }}:
				{{ beautify ? $t("app.yes") : $t("app.no") }}
			</v-card-text>
			<v-card-text class="subtitle-1 black--text ps-0">
				{{ $t("app.good_luck") }}
			</v-card-text>
			<v-btn depressed color="success" @click="generateReport()"
				>Download Report</v-btn
			>
		</v-card>

		<!-- Report Preview -->
		<div>
			<vue-html2pdf
				:show-layout="false"
				:float-layout="true"
				:enable-download="true"
				:preview-modal="false"
				:paginate-elements-by-height="1400"
				filename="hee hee"
				:pdf-quality="2"
				:manual-pagination="false"
				pdf-format="a4"
				pdf-orientation="portrait"
				@progress="onProgress($event)"
				@hasStartedGeneration="hasStartedGeneration()"
				@hasGenerated="hasGenerated($event)"
				ref="html2Pdf"
			>
				<section slot="pdf-content">
					<div style="padding: 40px;width: 100%">
						<v-row style="margin-top: 10px">
							<v-col><b>Student Name</b></v-col>
							<v-col>{{ form.user_name }}</v-col>
						</v-row>
						<v-row style="margin-top: 10px">
							<v-col><b>Teacher Name</b></v-col>
							<v-col>{{ form.teacher_name }}</v-col>
						</v-row>
						<!-- <v-row style="margin-top: 10px">
							<v-col><b>Grade</b></v-col>
							<v-col>{{ form.grade }}</v-col>
						</v-row> -->
						<v-row style="margin-top: 10px">
							<v-col><b>Reflection</b></v-col>
							<v-col>{{ form.reflection }}</v-col>
						</v-row>
						<v-row style="margin-top: 10px">
							<v-col><b>Help me used</b></v-col>
							<v-col>{{ beautify ? $t("app.yes") : $t("app.no") }}</v-col>
						</v-row>
						<v-row>
							<v-col>
								<StandardMindmap :map_data="step3_map"></StandardMindmap>
							</v-col>
						</v-row>
						<table
							style="width: 100%;margin-top: 30px;border-collapse: collapse;table-layout:fixed"
						>
							<thead>
								<tr>
									<th>{{ $t("app.step_round") }}</th>
									<th colspan="3">
										{{ $t("app.items_generated_by_user") }}
									</th>
								</tr>
								<tr>
									<th></th>
									<th>{{ $t("app.total_placed") }}</th>
									<th>{{ $t("app.placed_correctly") }}</th>
									<th>{{ $t("app.placed_incorrectly") }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in table_data" :key="index">
									<td>
										{{ $t("app.step") }} {{ item.step }} - {{ $t("app.round") }}
										{{ item.round }}
									</td>
									<td>
										{{ item.placed.length }}
									</td>
									<td>
										<span v-if="item.placed_correctly.length">
											{{ item.placed_correctly.length }}
											<br />
											({{ item.placed_correctly.join(", ") }})</span
										>
										<span v-else>{{ item.placed_correctly.length }}</span>
									</td>
									<td>
										<span v-if="item.placed_incorrectly.length">
											{{ item.placed_incorrectly.length }}
											<br />
											({{ item.placed_incorrectly.join(", ") }})
										</span>
										<span v-else>{{ item.placed_incorrectly.length }}</span>
									</td>
								</tr>
							</tbody>
						</table>
						<div style="margin-top: 30px">Sincerely,</div>
						<div style="margin-top: 10px">{{ form.user_name }}</div>
					</div>
				</section>
			</vue-html2pdf>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { sortArrayOfObjects } from "@/utils/helpers";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import rules from "@/utils/validation";
import VueHtml2pdf from "vue-html2pdf";

export default {
	name: "GenerateReport",

	data() {
		return {
			rules,
			table_data: null,
			form: {
				user_name: null,
				teacher_name: null,
				grade: this.$store.state.query.mindmap.grade,
				reflection: null,
			},
		};
	},

	components: {
		StandardMindmap,
		VueHtml2pdf,
	},

	computed: {
		...mapState({
			attempts: (state) => state.query.mindmap.attempts,
			step3_map: (state) => state.query.mindmap.step3_map,
			beautify: (state) => state.query.mindmap.beautify,
		}),
	},

	mounted() {
		this.prepareTableData();
	},

	methods: {
		generateReport() {
			this.$refs.html2Pdf.generatePdf();
		},

		getAccuracyCount(payload) {
			// The nodes user placed
			let placed = payload.data;
			// The nodes GPT suggested incorrect
			let incorrect = payload.feedback.split(":")[1];
			let placed_incorrectly = placed.filter((value) => {
				return incorrect.includes(value);
			});
			// The nodes which remain correct
			let placed_correctly = placed.filter((value) => {
				return !incorrect.includes(value);
			});
			return { placed, placed_correctly, placed_incorrectly };
		},

		prepareTableData() {
			let sorted = sortArrayOfObjects(this.attempts, "step");
			this.table_data = sorted.map((item) => {
				return { ...item, ...this.getAccuracyCount(item) };
			});
		},
	},
};
</script>

<style scoped>
table {
	border-collapse: collapse;
	width: 100%;
	table-layout: fixed;
	color: black;
}

th,
td {
	border: 2px solid black;
	padding: 8px;
	text-align: center;
}
</style>
