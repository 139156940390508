<template>
	<MindmapQuery></MindmapQuery>
</template>

<script>
import MindmapQuery from "@/components/shared/tools/query/mindmap/Index.vue";

export default {
	name: "MindMapQuery",

	components: {
		MindmapQuery,
	},
};
</script>

<style></style>
