<template>
	<div>
		<v-card v-if="step3_branches" flat class="wr_grey_1">
			<h3>
				{{ $t("app.add_additional_branches_to_map") }}
			</h3>
		</v-card>

		<v-row no-gutters>
			<v-col sm="8">
				<v-treeview v-if="branches && beautify" :items="branches"></v-treeview>
				<div v-if="!branches && step3_branches">
					{{ step3_branches.result.substring(0, 100) + "..." }}
				</div>
			</v-col>
			<v-col>
				<AppButton
					v-if="!beautify"
					label="app.read_more"
					:rounded="true"
					:small="true"
					:outlined="true"
					@click="beautify = true"
					color="error"
				></AppButton>
			</v-col>
			<v-col align="center">
				<AppButton
					label="app.submit"
					:rounded="false"
					:x_large="true"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</v-col>
		</v-row>

		<v-card v-if="step3_answer" flat class="wr_grey_1 mt-4">
			<v-card-text
				class="pt-0 black--text"
				v-if="step3_answer.result"
				:class="answer_styles"
				:key="'r'"
				v-html="step3_answer.result.replace('\n\n', '')"
			></v-card-text>
		</v-card>

		<v-card v-if="step3_map" flat height="700px" class="wr_grey_1 mt-4">
			<StandardMindmap
				:map_data="step3_map"
				@on-map-saved="onMapSave"
				@operation-performed="onOperationPerformed"
			></StandardMindmap>
		</v-card>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import { queryMindmapSteps, generateRandomString } from "@/utils/steps";
import {
	toolsQueryMixin,
	mindmapJSONMixin,
} from "@/mixins/tools/queryMixin.js";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

export default {
	name: "AddAdditionalInfo",

	mixins: [toolsQueryMixin, mindmapJSONMixin],

	data() {
		return {
			temp: [],
		};
	},

	components: {
		StandardMindmap,
	},

	computed: {
		...mapFields("query", {
			step2_map: "mindmap.step2_map",
			step3_map: "mindmap.step3_map",
			step3_branches: "mindmap.step3_branches",
			step3_answer: "mindmap.step3_answer",
			current_step: "mindmap.current_step",
			attempts: "mindmap.attempts",
			beautify: "mindmap.beautify",
			step1_question: "mindmap.step1_question",
		}),

		branches() {
			if (!this.step3_branches || !this.step3_branches.result) return null;

			let data = JSON.parse(this.step3_branches.result);

			let result = [];

			const convertDataStructure = (data) =>
				data.map(({ name, children }) => ({
					id: uid(),
					name,
					children: children.map(({ name, children }) => ({
						id: uid(),
						name: name,
					})),
				}));

			result = convertDataStructure(data.children);

			return result;
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtm-move-to-fav", (payload) => {
				try {
					if (!this.step3_map) {
						throw new Error("There is no map.", {
							cause: "werCustom",
						});
					}

					if (!this.step3_answer) {
						throw new Error("Please submit the mind map to get a feedback.", {
							cause: "werCustom",
						});
					}

					this.current_step = queryMindmapSteps[3];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async init() {
			try {
				this.$loader.start();

				// Setup the map
				if (!this.step3_map) {
					this.step3_map = structuredClone(this.step2_map);
				}

				if (!this.step3_branches) {
					let modified_json = this.reverseJSON(this.step3_map);
					// Send query
					let query = `The following JSON is representing a mind map- ${JSON.stringify(
						modified_json,
					)}. Generate the same format JSON with different information related to the root topic, ${
						this.step1_question
					}.`;
					this.step3_branches = await this.prepareQuery(query);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onMapSave(payload) {
			this.$store.commit("query/UPDATE_MAP", {
				step: 3,
				data: payload.data,
			});
		},

		async submit() {
			try {
				this.$loader.start();

				this.isMapModified(this.step2_map, this.step3_map);

				let modified_json = this.reverseJSON(this.step3_map);

				this.step3_answer = await this.prepareQuery(
					`Check each node if it should appear in this mind map then generate an array of the nodes that should not appear - ${JSON.stringify(
						modified_json,
					)}`,
				);

				// Push this answer as an attempt
				this.attempts.push({
					step: 3,
					round: this.currentRound(3),
					data: this.temp,
					feedback: this.step3_answer.result,
				});

				this.temp = [];
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtm-move-to-fav");
	},
};
</script>

<style></style>
