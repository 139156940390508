<template>
	<QueryActions :actions="actions" @redirect-to="redirectTo"></QueryActions>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryMindmapSteps } from "@/utils/steps";
import QueryActions from "@/components/shared/tools/query/_common/Actions.vue";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "ChooseQuestionActions",

	data() {
		return {
			actions: [
				{
					title: "app.want_to_update_map_and_get_feedback",
					value: 1,
					color: "teal",
				},
				{
					title: "app.want_to_see_info_about_topic",
					value: 2,
					color: "brown",
				},
				{
					title: "app.want_practice_with_new_topic",
					value: 3,
					color: "pink",
				},
				{
					title: "app.learned_creating_map_and_want_move_forward",
					value: 4,
					color: "purple",
				},
				{
					title: "app.generate_report_of_efforts",
					value: 5,
					color: "green",
				},
			],
		};
	},

	components: {
		QueryActions,
	},

	computed: {
		...mapFields("query", {
			current_step: "mindmap.current_step",
		}),
	},

	methods: {
		redirectTo(value) {
			switch (value) {
				case 1:
					this.current_step = queryMindmapSteps[1];
					break;
				case 2:
					this.current_step = queryMindmapSteps[3];
					break;
				case 3:
					this.current_step = queryMindmapSteps[0];
					break;
				case 4:
					this.$router.push({
						name: isArrayHavingItem(
							this.$defines.UNAUTHORIZED_ROUTES,
							this.$route.name,
						)
							? "rt6lio_AI"
							: "UserDashboard",
					});
					break;
				case 5:
					this.current_step = queryMindmapSteps[5];
					break;
			}
		},
	},
};
</script>

<style></style>
